/*! CSS Used from: https://rrdevs.net/demos/html/techex/assets/css/icons.css */
.fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.fa-arrow-right:before {
  content: "\f061";
}
.fas {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
}
/*! CSS Used from: https://rrdevs.net/demos/html/techex/assets/css/bootstrap.min.css */
*,
::after,
::before {
  box-sizing: border-box;
}
h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1 {
    font-size: 2.5rem;
  }
}
a {
  color: #0d6efd;
  text-decoration: underline;
}
a:hover {
  color: #0a58ca;
}
.container-fluid {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.col-12 {
  flex: 0 0 auto;
  width: 100%;
}
@media (min-width: 1200px) {
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
}
.align-items-center {
  align-items: center !important;
}
.mt-4 {
  margin-top: 1.5rem !important;
}
.text-center {
  text-align: center !important;
}
.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}
@media (min-width: 1200px) {
  .mt-xl-0 {
    margin-top: 0 !important;
  }
}
/*! CSS Used from: https://rrdevs.net/demos/html/techex/assets/css/style.css */
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
div,
a,
h1,
section {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none !important;
  border: 0;
}
::placeholder {
  color: #666;
  opacity: 1;
}
a {
  position: relative;
}
a:hover,
a:focus {
  outline: 0;
  text-decoration: none;
}
h1 {
  font-family: "Circular Std", sans-serif;
  margin: 0px;
  font-weight: 700;
  color: #211e3b;
}
h1 {
  font-size: 42px;
  line-height: 55px;
  letter-spacing: -3px;
}
@media (max-width: 767px) {
  h1 {
    font-size: 2rem;
    line-height: 3rem;
  }
}
a {
  text-decoration: none;
  font-weight: 600;
  outline: none !important;
  cursor: pointer;
  font-size: 17px;
  line-height: 32px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  color: #211e3b;
}
.text-white h1 {
  color: #fff !important;
}
.bg-cover {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: center;
}
section {
  position: relative;
  z-index: 1;
}
.theme-btn {
  line-height: 1;
  display: inline-block;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  text-transform: uppercase;
}
.theme-btn {
  color: #fff;
  padding: 20px 40px;
  border-radius: 5px;
  font-size: 14px;
  background-color: #086ad7;
  border: 1px solid #086ad7;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
@media (max-width: 767px) {
  .theme-btn {
    padding: 17px 30px;
    font-size: 13px;
  }
}
@media (max-width: 580px) {
  .theme-btn {
    padding: 16px 26px;
  }
}
@media (max-width: 480px) {
  .theme-btn {
    padding: 15px 25px;
  }
}
.theme-btn i {
  margin-left: 10px;
}
.theme-btn.minimal-btn {
  color: #211e3b;
  border: 1px solid #b0cff2;
  background: transparent;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
}
.theme-btn.minimal-btn:hover {
  background-color: #086ad7;
  border: 1px solid #086ad7;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
  color: #fff;
}
.theme-btn:hover {
  background-color: #000;
  border: 1px solid #000;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
  color: #fff;
}
.section-bg {
  background-color: #211e3b;
}
.cta-banner {
  z-index: 3;
  position: relative;
}
.cta-banner .container-fluid {
  padding-top: 90px;
  padding-bottom: 85px;
  border-radius: 7px;
  position: relative;
  z-index: 3;
  margin: 0 auto;
}
@media (min-width: 1650px) {
  .cta-banner .container-fluid {
    max-width: 1600px;
    width: 100%;
  }
}
@media (max-width: 1649px) {
  .cta-banner .container-fluid {
    max-width: 96%;
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (max-width: 1199px) {
  .cta-banner .container-fluid {
    max-width: 90%;
  }
}
@media (max-width: 767px) {
  .cta-banner .container-fluid {
    max-width: 92%;
    padding: 50px 15px;
  }
}
@media (max-width: 500px) {
  .cta-banner .container-fluid {
    max-width: 96%;
    padding: 40px 20px;
  }
}
.cta-banner .container-fluid::after {
  border-width: 3px;
  border-color: rgb(255, 150, 0);
  border-style: solid;
  border-radius: 50%;
  position: absolute;
  width: 14px;
  height: 14px;
  right: 7%;
  top: 60px;
  content: "";
  z-index: -1;
}
@media (max-width: 767px) {
  .cta-banner .container-fluid::after {
    top: 30px;
  }
}
.cta-banner .container-fluid::before {
  border-width: 5px;
  border-color: #e8524a;
  border-style: solid;
  border-radius: 50%;
  position: absolute;
  width: 24px;
  height: 24px;
  left: 6%;
  bottom: 60px;
  content: "";
  z-index: -1;
}
.cta-banner .cta-content {
  max-width: 1170px;
  width: 100%;
  margin: 0px auto;
}
@media (max-width: 500px) {
  .cta-banner .cta-content h1 {
    font-size: 30px;
  }
}
.cta-banner .minimal-btn {
  color: #fff;
}
