/*! CSS Used from: Embedded */
svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.svg-inline--fa.fa-w-14 {
  width: 0.875em;
}
/*! CSS Used from: https://wowtheme7.com/tf/agiletech/assets/css/bootstrap.min.css */
*,
::after,
::before {
  box-sizing: border-box;
}
.Services h2,
.Services h5,
.Services h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.Services h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  .Services h2 {
    font-size: 2rem;
  }
}
.Services h5 {
  font-size: 1.25rem;
}
.Services h6 {
  font-size: 1rem;
}
.Services p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.Services a {
  color: #0d6efd;
  text-decoration: underline;
}
.Services a:hover {
  color: #0a58ca;
}
.Services img,
.Services svg {
  vertical-align: middle;
}
.Services .container {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .Services .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .Services .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .Services .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .Services .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .Services .container {
    max-width: 1320px;
  }
}
.Services .row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}
.Services .row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
@media (min-width: 768px) {
  .Services .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media (min-width: 992px) {
  .Services .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
}
.Services .btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .Services .btn {
    transition: none;
  }
}
.Services .btn:hover {
  color: #212529;
}
.Services .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.Services .btn:disabled {
  pointer-events: none;
  opacity: 0.65;
}
.Services .text-center {
  text-align: center !important;
}
/*! CSS Used from: https://wowtheme7.com/tf/agiletech/assets/css/fontawesome.min.css */
.Services .fa-plus:before {
  content: "\f067";
}
/*! CSS Used from: https://wowtheme7.com/tf/agiletech/assets/css/style.css */
/* * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
} */
.Services h2 {
  font-size: 40px;
  line-height: 1.2380952381;
}
.Services h5 {
  font-size: 20px;
  line-height: 1.3380952381;
}
.Services h6 {
  font-size: 16px;
  line-height: 1.2380952381;
}
.Services h2,
.Services h5,
.Services h6 {
  color: var(--heading-color);
  font-weight: 600;
  font-family: var(--heading-font);
}
.Services p {
  color: var(--paragraph-color);
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  margin-bottom: 10px;
}
.Services a {
  color: inherit;
  text-decoration: none;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.Services a,
.Services a:hover,
.Services a:focus,
.Services a:active {
  text-decoration: none;
  outline: none;
  color: inherit;
}
.Services a:hover {
  color: var(--main-color);
}
.Services img {
  max-width: 100%;
}
.Services .btn {
  height: 55px;
  line-height: 55px;
  padding: 0 30px;
  border-radius: 0;
  overflow: hidden;
  position: relative;
  border: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  font-weight: 600;
  border-radius: 30px;
  z-index: 0;
}
.Services .btn:focus,
.Services .btn:active {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.Services .btn:after {
  content: "";
  background: var(--heading-color);
  position: absolute;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  z-index: -1;
  height: 100%;
  left: -35%;
  top: 0;
  -webkit-transform: skew(30deg);
  transform: skew(30deg);
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  width: 0;
}
.Services .btn:hover:after {
  height: 100%;
  width: 135%;
}
.Services .btn svg {
  font-size: 12px;
  margin-top: 20px;
  margin-left: 6px;
  float: right;
}
.Services .btn-border-base {
  color: var(--heading-color);
  border: 2px solid var(--main-color);
  line-height: 52px;
}
.Services .btn-border-base:after {
  background: var(--main-color);
}
.Services .btn-border-base:hover,
.Services .btn-border-base:focus {
  color: #fff;
}
.Services .bg-relative {
  position: relative;
}
.Services .pd-top-100 {
  padding-top: 100px;
}
.Services .position-bottom-left {
  position: relative;
  left: 30px;
  top: 538px;
  bottom: 150px;
  width: 50px;
}
.Services .top_image_bounce {
  -webkit-animation: top-image-bounce 3s infinite ease-in-out;
  animation: top-image-bounce 3s infinite ease-in-out;
}
.Services .section-title {
  margin-bottom: 50px;
  position: relative;
}
.Services .section-title .sub-title {
  border-radius: 30px;
  -webkit-box-shadow: 0 4.4px 30px rgba(19, 16, 34, 0.1);
  box-shadow: 0 4.4px 30px rgba(19, 16, 34, 0.1);
  background: #fff;
  padding: 8px 20px;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
  letter-spacing: 1px;
  position: relative;
}
.Services .section-title .title {
  margin-bottom: 0;
  margin-top: 0;
}
.Services .section-title .title span {
  color: var(--main-color);
}
.Services .single-service-inner {
  background: #fff;
  padding: 40px 35px;
  height: 418px;
  margin-bottom: 30px;
  border-radius: 24px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-shadow: 0px 4px 20px rgba(19, 16, 34, 0.06);
  box-shadow: 0px 4px 20px rgba(19, 16, 34, 0.06);
}
.Services .single-service-inner .thumb {
  margin-bottom: 35px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.Services .single-service-inner .thumb img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.Services .single-service-inner .details h5 {
  margin-bottom: 20px;
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.Services .single-service-inner .details p {
  margin-bottom: 28px;
}
.Services .single-service-inner:hover {
  -webkit-box-shadow: 0px 4px 20px rgba(19, 16, 34, 0.1);
  box-shadow: 0px 4px 20px rgba(19, 16, 34, 0.1);
}
.Services .single-service-inner:hover .thumb img {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
/*! CSS Used from: https://wowtheme7.com/tf/agiletech/assets/css/responsive.css */
@media all and (min-width: 1600px) {
  .Services .container {
    max-width: 1280px;
  }
  .Services .position-bottom-left {
    position: absolute;
    left: 90px;
    bottom: 180px;
  }
}
@media all and (max-width: 1199px) {
  .Services .single-service-inner {
    padding: 30px 25px;
  }
}
@media all and (max-width: 575px) {
  .Services .section-title .title {
    font-size: 32px;
  }
}
@media all and (max-width: 360px) {
  .Services .section-title .title {
    font-size: 30px;
  }
}
/*! CSS Used keyframes */
@-webkit-keyframes top-image-bounce {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  50% {
    -webkit-transform: translateY(12px);
    transform: translateY(12px);
  }
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
@keyframes top-image-bounce {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  50% {
    -webkit-transform: translateY(12px);
    transform: translateY(12px);
  }
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
