/*! CSS Used from: https://rrdevs.net/demos/html/techex/assets/css/icons.css */
.fab,
.fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.fa-arrow-right:before {
  content: "\f061";
}
.fa-behance:before {
  content: "\f1b4";
}
.fa-facebook-f:before {
  content: "\f39e";
}
.fa-twitter:before {
  content: "\f099";
}
.fa-youtube:before {
  content: "\f167";
}
.fab {
  font-family: "Font Awesome 5 Brands";
}
.fas {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
}
/*! CSS Used from: https://rrdevs.net/demos/html/techex/assets/css/bootstrap.min.css */
*,
::after,
::before {
  box-sizing: border-box;
}
h1,
h4 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1 {
    font-size: 2.5rem;
  }
}
h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4 {
    font-size: 1.5rem;
  }
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
a {
  color: #0d6efd;
  text-decoration: underline;
}
a:hover {
  color: #0a58ca;
}
.container {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.col-12 {
  flex: 0 0 auto;
  width: 100%;
}
@media (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
}
.align-items-center {
  align-items: center !important;
}
.mt-3 {
  margin-top: 1rem !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.text-start {
  text-align: left !important;
}
@media (min-width: 768px) {
  .text-md-end {
    text-align: right !important;
  }
}
/*! CSS Used from: https://rrdevs.net/demos/html/techex/assets/css/style.css */
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
div,
a,
p,
h1,
h4,
section {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none !important;
  border: 0;
}
::placeholder {
  color: #666;
  opacity: 1;
}
a {
  position: relative;
}
a:hover,
a:focus {
  outline: 0;
  text-decoration: none;
}
.container {
  width: 100%;
}
@media (min-width: 1300px) {
  .container {
    max-width: 1200px;
  }
}
h1,
h4 {
  font-family: "Circular Std", sans-serif;
  margin: 0px;
  font-weight: 700;
  color: #211e3b;
}
h1 {
  font-size: 42px;
  line-height: 55px;
  letter-spacing: -3px;
}
@media (max-width: 767px) {
  h1 {
    font-size: 2rem;
    line-height: 3rem;
  }
}
h4 {
  font-size: 20px;
  line-height: 28px;
}
a {
  text-decoration: none;
  font-weight: 600;
  outline: none !important;
  cursor: pointer;
  font-size: 17px;
  line-height: 32px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  color: #211e3b;
}
p {
  margin: 0px;
}
.mb-40 {
  margin-bottom: 40px;
}
.bg-cover {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: center;
}
.bg-center {
  background-position: center;
}
section {
  position: relative;
  z-index: 1;
}
.theme-btn {
  line-height: 1;
  display: inline-block;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  text-transform: uppercase;
}
.theme-btn {
  color: #fff;
  padding: 20px 40px;
  border-radius: 5px;
  font-size: 14px;
  background-color: #086ad7;
  border: 1px solid #086ad7;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
@media (max-width: 767px) {
  .theme-btn {
    padding: 17px 30px;
    font-size: 13px;
  }
}
@media (max-width: 580px) {
  .theme-btn {
    padding: 16px 26px;
  }
}
@media (max-width: 480px) {
  .theme-btn {
    padding: 15px 25px;
  }
}
.theme-btn i {
  margin-left: 10px;
}
.theme-btn.off-white {
  background-color: #efeff1;
  color: #211e3b;
  border: 1px solid #efeff1;
}
.theme-btn:hover {
  background-color: #000;
  border: 1px solid #000;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
  color: #fff;
}
.section-title {
  position: relative;
  z-index: 1;
}
.section-title p {
  color: #086ad7;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 15px;
  line-height: 1;
  text-transform: uppercase;
}
.section-title h1 {
  line-height: 50px;
  font-weight: 900;
  text-transform: capitalize;
}
.section-padding {
  padding: 130px 0px;
}
@media (max-width: 991px) {
  .section-padding {
    padding: 100px 0px;
  }
}
@media (max-width: 767px) {
  .section-padding {
    padding: 80px 0px;
  }
}
.team-members-list {
  display: flex;
  justify-content: center;
}
.team-members-list .single-team-member {
  padding: 0px 30px 30px;
  position: relative;
  z-index: 1;
  border-radius: 7px;
  text-align: center;
  overflow: hidden;
  margin-top: 40px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.team-members-list .single-team-member::before {
  position: absolute;
  background-color: #f5f5f5;
  left: 0;
  bottom: 0;
  content: "";
  width: 100%;
  height: 75%;
  border-radius: 7px;
  z-index: -1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.team-members-list .single-team-member:hover h4,
.team-members-list .single-team-member:hover p,
.team-members-list .single-team-member.active h4,
.team-members-list .single-team-member.active p {
  color: #fff;
}
.team-members-list .single-team-member:hover::before,
.team-members-list .single-team-member.active::before {
  background-color: #086ad7;
}
.team-members-list .single-team-member .member-img {
  border-radius: 7px;
  width: 210px;
  height: 210px;
  background-color: #eee;
  margin: 0 auto;
  margin-bottom: 20px;
  background-position: center top;
}
.team-members-list .single-team-member .member-bio {
  margin-bottom: 20px;
}
.team-members-list .single-team-member .member-bio h4,
.team-members-list .single-team-member .member-bio p {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.team-members-list .single-team-member .member-bio p {
  line-height: 1;
  margin-top: 5px;
}
.team-members-list .single-team-member .social-profile a {
  display: inline-block;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border: 1px solid #84b5eb;
  color: #84b5eb;
  text-align: center;
  font-size: 15px;
  margin: 0 2px;
}
.team-members-list .single-team-member .social-profile a:hover {
  background: #fff;
  color: #086ad7;
  border: 1px solid #fff;
}
/*! CSS Used fontfaces */
@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url(https://rrdevs.net/demos/html/techex/assets/fonts/fa-brands-400.eot);
  src: url(https://rrdevs.net/demos/html/techex/assets/fonts/fa-brands-400.eot?#iefix)
      format("embedded-opentype"),
    url(https://rrdevs.net/demos/html/techex/assets/fonts/fa-brands-400.woff2)
      format("woff2"),
    url(https://rrdevs.net/demos/html/techex/assets/fonts/fa-brands-400.woff)
      format("woff");
}
@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url(https://rrdevs.net/demos/html/techex/assets/fonts/fa-light-300.eot);
  src: url(https://rrdevs.net/demos/html/techex/assets/fonts/fa-light-300.eot?#iefix)
      format("embedded-opentype"),
    url(https://rrdevs.net/demos/html/techex/assets/fonts/fa-light-300.woff2)
      format("woff2"),
    url(https://rrdevs.net/demos/html/techex/assets/fonts/fa-light-300.woff)
      format("woff");
}
@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(https://rrdevs.net/demos/html/techex/assets/fonts/fa-regular-400.eot);
  src: url(https://rrdevs.net/demos/html/techex/assets/fonts/fa-regular-400.eot?#iefix)
      format("embedded-opentype"),
    url(https://rrdevs.net/demos/html/techex/assets/fonts/fa-regular-400.woff2)
      format("woff2"),
    url(https://rrdevs.net/demos/html/techex/assets/fonts/fa-regular-400.woff)
      format("woff");
}
@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url(https://rrdevs.net/demos/html/techex/assets/fonts/fa-solid-900.eot);
  src: url(https://rrdevs.net/demos/html/techex/assets/fonts/fa-solid-900.eot?#iefix)
      format("embedded-opentype"),
    url(https://rrdevs.net/demos/html/techex/assets/fonts/fa-solid-900.woff2)
      format("woff2"),
    url(https://rrdevs.net/demos/html/techex/assets/fonts/fa-solid-900.woff)
      format("woff");
}
@font-face {
  font-family: "Circular Std";
  src: url("https://rrdevs.net/demos/html/techex/assets/fonts/CircularStd-MediumItalic.eot");
  src: local("Circular Std Medium Italic"), local("CircularStd-MediumItalic"),
    url("https://rrdevs.net/demos/html/techex/assets/fonts/CircularStd-MediumItalic.eot?#iefix")
      format("embedded-open_type"),
    url("https://rrdevs.net/demos/html/techex/assets/fonts/CircularStd-MediumItalic.woff2")
      format("woff2"),
    url("https://rrdevs.net/demos/html/techex/assets/fonts/CircularStd-MediumItalic.woff")
      format("woff"),
    url("https://rrdevs.net/demos/html/techex/assets/fonts/CircularStd-MediumItalic.ttf")
      format("true_type");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Circular Std";
  src: url("https://rrdevs.net/demos/html/techex/assets/fonts/CircularStd-Black.eot");
  src: local("Circular Std Black"), local("CircularStd-Black"),
    url("https://rrdevs.net/demos/html/techex/assets/fonts/CircularStd-Black.eot?#iefix")
      format("embedded-opentype"),
    url("https://rrdevs.net/demos/html/techex/assets/fonts/CircularStd-Black.woff2")
      format("woff2"),
    url("https://rrdevs.net/demos/html/techex/assets/fonts/CircularStd-Black.woff")
      format("woff"),
    url("https://rrdevs.net/demos/html/techex/assets/fonts/CircularStd-Black.ttf")
      format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Circular Std";
  src: url("https://rrdevs.net/demos/html/techex/assets/fonts/CircularStd-Medium.eot");
  src: local("Circular Std Medium"), local("CircularStd-Medium"),
    url("https://rrdevs.net/demos/html/techex/assets/fonts/CircularStd-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("https://rrdevs.net/demos/html/techex/assets/fonts/CircularStd-Medium.woff2")
      format("woff2"),
    url("https://rrdevs.net/demos/html/techex/assets/fonts/CircularStd-Medium.woff")
      format("woff"),
    url("https://rrdevs.net/demos/html/techex/assets/fonts/CircularStd-Medium.ttf")
      format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Circular Std";
  src: url("https://rrdevs.net/demos/html/techex/assets/fonts/CircularStd-Bold.eot");
  src: local("Circular Std Bold"), local("CircularStd-Bold"),
    url("https://rrdevs.net/demos/html/techex/assets/fonts/CircularStd-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("https://rrdevs.net/demos/html/techex/assets/fonts/CircularStd-Bold.woff2")
      format("woff2"),
    url("https://rrdevs.net/demos/html/techex/assets/fonts/CircularStd-Bold.woff")
      format("woff"),
    url("https://rrdevs.net/demos/html/techex/assets/fonts/CircularStd-Bold.ttf")
      format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Circular Std";
  src: url("https://rrdevs.net/demos/html/techex/assets/fonts/CircularStd-BlackItalic.eot");
  src: local("Circular Std Black Italic"), local("CircularStd-BlackItalic"),
    url("https://rrdevs.net/demos/html/techex/assets/fonts/CircularStd-BlackItalic.eot?#iefix")
      format("embedded-opentype"),
    url("https://rrdevs.net/demos/html/techex/assets/fonts/CircularStd-BlackItalic.woff2")
      format("woff2"),
    url("https://rrdevs.net/demos/html/techex/assets/fonts/CircularStd-BlackItalic.woff")
      format("woff"),
    url("https://rrdevs.net/demos/html/techex/assets/fonts/CircularStd-BlackItalic.ttf")
      format("truetype");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Circular Std";
  src: url("https://rrdevs.net/demos/html/techex/assets/fonts/CircularStd-BoldItalic.eot");
  src: local("Circular Std Bold Italic"), local("CircularStd-BoldItalic"),
    url("https://rrdevs.net/demos/html/techex/assets/fonts/CircularStd-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("https://rrdevs.net/demos/html/techex/assets/fonts/CircularStd-BoldItalic.woff2")
      format("woff2"),
    url("https://rrdevs.net/demos/html/techex/assets/fonts/CircularStd-BoldItalic.woff")
      format("woff"),
    url("https://rrdevs.net/demos/html/techex/assets/fonts/CircularStd-BoldItalic.ttf")
      format("truetype");
  font-weight: bold;
  font-style: italic;
}
