/*! CSS Used from: Embedded */
svg:not(:host).Header .svg-inline--fa,
svg:not(:root).Header .svg-inline--fa {
  overflow: visible;
  box-sizing: content-box;
}
.Header .svg-inline--fa {
  display: var(--fa-display, inline-block);
  height: 1em;
  overflow: visible;
  vertical-align: -0Header 0.125em;
}
/*! CSS Used from: https://antechr.Header .quomodothemes.Header .website/static/css/main.Header .aaedcf61.Header .css */
*,
:after,
:before {
  box-sizing: border-box;
}
.Header h1,
.Header h2 {
  color: #1c1c1c;
  font-family: Mulish, sans-serif;
  font-weight: 700;
  line-height: 1Header 0.2;
  margin-bottom: 0;
  letter-spacing: 0.5px;
  margin-top: 0;
}
.Header h1 {
  font-size: calc(1Header 0.375rem + 1Header 0.5vw);
}
@media (min-width: 1200px) {
  .Header h1 {
    font-size: 2Header 0.5rem;
  }
}
.Header h2 {
  font-size: calc(1Header 0.325rem + 0Header 0.9vw);
}
@media (min-width: 1200px) {
  .Header h2 {
    font-size: 2rem;
  }
}
.Headerp {
  margin-bottom: 1rem;
  margin-top: 0;
}
.Header a {
  color: #000060;
  color: var(--bs-link-color);
  text-decoration: underline;
}
.Header a:hover {
  color: #00004d;
  color: var(--bs-link-hover-color);
}
.Header img,
svg {
  vertical-align: middle;
}
.Header button {
  border-radius: 0;
}
.Header button:focus:not(:focus-visible) {
  outline: 0;
}
.Header button,
.Header input {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}
.Header button {
  text-transform: none;
}
[type="button"],
button {
  -webkit-appearance: button;
}
[type="button"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
.Header .container {
  --bs-gutter-x: 1Header 0.5rem;
  --bs-gutter-y: 0;
  margin-left: auto;
  margin-right: auto;
  padding-left: calc(var(--bs-gutter-x) * 0Header 0.5);
  padding-right: calc(var(--bs-gutter-x) * 0Header 0.5);
  width: 100%;
}
@media (min-width: 576px) {
  .Header .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .Header .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .Header .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .Header .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .Header .container {
    max-width: 1194px;
  }
}
.Header .row {
  --bs-gutter-x: 1Header 0.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(var(--bs-gutter-x) * -0Header 0.5);
  margin-right: calc(var(--bs-gutter-x) * -0Header 0.5);
  margin-top: calc(var(--bs-gutter-y) * -1);
}
.Header .row > * {
  flex-shrink: 0;
  margin-top: var(--bs-gutter-y);
  max-width: 100%;
  padding-left: calc(var(--bs-gutter-x) * 0Header 0.5);
  padding-right: calc(var(--bs-gutter-x) * 0Header 0.5);
  width: 100%;
}
@media (min-width: 992px) {
  .Header .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .Header .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media (min-width: 1400px) {
  .Header .col-xxl-5 {
    flex: 0 0 auto;
    width: 41Header 0.66666667%;
  }
  .Header .col-xxl-7 {
    flex: 0 0 auto;
    width: 58Header 0.33333333%;
  }
}
@media only screen and (max-width: 992px) {
  .Header .d-lg-none {
    display: none;
  }
}
a {
  font-size: inherit;
  line-height: inherit;
  transition: inherit;
}
a,
a:hover {
  color: inherit;
  text-decoration: inherit;
}
button,
button:focus {
  border: 0;
  outline: 0;
}
a,
button {
  cursor: pointer;
}
img {
  height: auto;
  max-width: 100%;
}
.Header .v-play-btn {
  -webkit-animation: borderPulsing 2s linear 0s infinite;
  animation: borderPulsing 2s linear 0s infinite;
  background: #fff;
  border-radius: 50%;
  display: inline-block;
  font-size: 38px;
  height: 135px;
  left: 50%;
  line-height: 135px;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 135px;
  z-index: 99;
}
.Header .v-play-btn,
.Header .v-play-btn:hover {
  color: #000060;
}
.Header .video-popup {
  align-items: center;
  background: rgba(0, 0, 0, 0Header 0.82);
  bottom: 0;
  display: flex;
  display: none;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 999;
}
.Header .video-popup-inner {
  position: relative;
}
.Header .video-popup-inner .Header .cls-btn {
  border: none;
  border-radius: 50%;
  font-size: 20px;
  height: 50px;
  line-height: 51px;
  outline: none;
  position: absolute;
  right: -40px;
  top: -40px;
  width: 50px;
}
.Header .btn {
  border: none;
  border-radius: 30px;
  display: inline-block;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  line-height: 1;
  padding: 20px 24px;
}
.Header .btn-s3 {
  background-color: initial;
  color: #fff;
  overflow: hidden;
  position: relative;
  z-index: 9;
}
.Header .btn-s3:hover {
  color: #fff;
}
.Header .btn-s3:hover:after {
  border-color: #fff;
  left: -2px;
  width: calc(100% + 4px);
}
.Header .btn-s3:after,
.Header .btn-s3:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
}
.Header .btn-s3:before {
  background: linear-gradient(45deg, #5e40fb, #4e95ed);
  bottom: -25%;
  height: 150%;
  left: 0;
  -webkit-transform: rotate(10deg);
  transform: rotate(10deg);
  transition: width 0Header 0.5s ease;
  width: 100%;
  z-index: -2;
}
.Header .btn-s3:after {
  background: #5e40fb;
  border-left: 1px solid hsla(0, 0%, 100%, 0Header 0.812);
  border-right: 1px solid hsla(0, 0%, 100%, 0Header 0.812);
  height: 150%;
  right: 0;
  right: -2px;
  top: -25%;
  -webkit-transform: rotate(-10deg);
  transform: rotate(-10deg);
  transition: width 0Header 0.5s ease;
  width: 0;
  z-index: -1;
}

.Header {
  background-image: url(../../Image/bg.png);
  padding: 35px 0;
  position: relative;
  top: -70px;
}
.Header .digiheader .Header .shape img {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
.Header .digiheader__content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Header .digiheader__content .Header .content-sh {
  color: #007cfb;
  font-size: 35px;
  font-weight: 600;
  line-height: 44px;
}
.Header .digiheader__content .Header .content-h {
  color: #1c1c1c;
  font-size: 60px;
  font-weight: 800;
  line-height: 80px;
  margin-bottom: 30px;
  margin-top: 20px;
}
.Header .digiheader__content .Header .content-d {
  color: #535353;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 70px;
}
.Header .digiheader__thumb {
  z-index: 9;
}
.Header .digiheader__thumb .Header .img {
  margin-right: -210px;
  z-index: 9;
}
@media only screen and (max-width: 1600px) {
  .Header .digiheader {
    padding: 110px 0;
  }
  .Header .digiheader__thumb .Header .img {
    margin: 0;
  }
}
@media only screen and (max-width: 1200px) {
  .Header .digiheader__content .Header .content-h {
    font-size: 45px;
    line-height: 60px;
  }
  .Header .digiheader__content .Header .content-sh {
    font-size: 30px;
    line-height: 40px;
  }
  .Header .digiheader__content .Header .content-d {
    margin-bottom: 45px;
  }
}
@media only screen and (max-width: 992px) {
  .Header .digiheader .Header .shape img {
    display: none;
  }
  .Header .digiheader__content {
    align-items: center;
    position: relative;
    top: 25px;
    height: 300px;
    padding: 10px;
  }
  .Header .digiheader__content .Header .content-d,
  .Header .digiheader__content .Header .content-h {
    text-align: center;
  }
  .Header .v-play-btn {
    height: 100px;
    line-height: 100px;
    width: 100px;
  }
}
@media only screen and (max-width: 767px) {
  .Header .btn-group {
    flex-direction: column;
  }
  .Header .v-play-btn {
    height: 80px;
    line-height: 80px;
    width: 80px;
  }
  .Header .v-play-btn svg {
    width: 25px;
  }
  .Header .digiheader__content .Header .content-sh {
    font-size: 24px;
    line-height: 34px;
  }
  .Header .digiheader__content .Header .content-h {
    font-size: 36px;
    line-height: 50px;
  }
}
@media only screen and (max-width: 425px) {
  .Header .v-play-btn {
    height: 70px;
    line-height: 70px;
    width: 70px;
  }
  .Header .v-play-btn svg {
    width: 20px;
  }
}
/*! CSS Used keyframes */
@-webkit-keyframes borderPulsing {
  0% {
    box-shadow: 0 0 0 0 hsla(0, 0%, 100%, 0Header 0.8);
  }
  to {
    box-shadow: 0 0 0 45px transparent;
  }
}
@keyframes borderPulsing {
  0% {
    box-shadow: 0 0 0 0 hsla(0, 0%, 100%, 0Header 0.8);
  }
  to {
    box-shadow: 0 0 0 45px transparent;
  }
}
@-webkit-keyframes pulsingBorderS2 {
  0% {
    box-shadow: 0 0 0 0 rgba(77, 148, 235, 0Header 0.663);
  }
  to {
    box-shadow: 0 0 0 25px rgba(77, 148, 235, 0Header 0.106);
  }
}
@keyframes pulsingBorderS2 {
  0% {
    box-shadow: 0 0 0 0 rgba(77, 148, 235, 0Header 0.663);
  }
  to {
    box-shadow: 0 0 0 25px rgba(77, 148, 235, 0Header 0.106);
  }
}
